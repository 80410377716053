import { readCookie } from "@zapier/cookies";
import { useCurrentAccountId } from "@zapier/identity";
import { ClientUserProfile, useUser } from "lib/context/user-context";
import { relogin } from "lib/utils/auth";
import { isFailedToFetchError } from "lib/utils/isFailedToFetchError";
import React, { ComponentType, useEffect, useMemo, useState } from "react";
import useMaintenance from "./useMaintenance";
import { isEqual } from "lodash";

/**
 * @ignore
 */
const defaultOnRedirecting = (): JSX.Element => <></>;

/**
 * @ignore
 */
const defaultOnError = (): JSX.Element => <></>;

/**
 * Options for the withPageAuthRequired Higher Order Component
 *
 * @category Client
 */
export interface WithPageAuthRequiredOptions {
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   returnTo: '/profile'
   * });
   * ```
   *
   * Add a path to return the user to after login.
   */
  returnTo?: string;
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * });
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onError: error => <div>Error: {error.message}</div>
   * });
   * ```
   *
   * Render a fallback in case of error fetching the user from the profile API route.
   */
  onError?: (error: Error) => JSX.Element;
}

/**
 * @ignore
 */
export interface WithPageAuthRequiredProps {
  user: ClientUserProfile;
  [key: string]: any;
}

/**
 * ```js
 * const MyProtectedPage = withPageAuthRequired(MyPage);
 * ```
 *
 * When you wrap your pages in this Higher Order Component and an anonymous user visits your page
 * they will be redirected to the login page and then returned to the page they were redirected from (after login).
 *
 * @category Client
 */
export type WithPageAuthRequired = <P extends WithPageAuthRequiredProps>(
  Component: ComponentType<P>,
  options?: WithPageAuthRequiredOptions
) => React.FC<Omit<P, "user">>;

const withPageAuthRequired: WithPageAuthRequired = (
  Component,
  options = {}
) => {
  return function WithPageAuthRequired(props): JSX.Element {
    const {
      returnTo,
      onRedirecting = defaultOnRedirecting,
      onError = defaultOnError,
    } = options;
    const { user, error, isLoading, syncSession } = useUser();
    const isMaintenanceModeOn = useMaintenance();

    /**
     * Watch the value of the `ssohint` cookie. If this changes, it means the user
     * has logged out in the monolith, so we need to log them out of interfaces too.
     *
     * Similarly, if their `currentAccountId` cookie changes, this indicates there was
     * an account change in the monolith, so we need to sync.
     */
    const [ssoHint, setCurrentSsoHint] = useState(readCookie("ssohint"));
    const currentAccountId = useCurrentAccountId();
    const [prevAccountId, setPrevAccountId] = useState(currentAccountId);

    const checkAndSyncSession = useMemo(
      () => () => {
        const ssoHintChanged = !isEqual(ssoHint, readCookie("ssohint"));
        const accountIdChanged = !isEqual(prevAccountId, currentAccountId);

        if (ssoHintChanged || accountIdChanged) {
          setCurrentSsoHint(readCookie("ssohint"));
          setPrevAccountId(currentAccountId);
          void syncSession();
        }
      },
      [ssoHint, currentAccountId, prevAccountId, syncSession]
    );

    useEffect(() => {
      const checkSessionInterval = setInterval(checkAndSyncSession, 500);
      return () => {
        clearInterval(checkSessionInterval);
      };
    }, [checkAndSyncSession]);

    useEffect(() => {
      if (isMaintenanceModeOn || isLoading) return;
      if (user && (!error || isFailedToFetchError(error))) return;

      relogin(returnTo);
    }, [user, error, isLoading, returnTo, isMaintenanceModeOn]);

    if (error) return onError(error);
    if (user) return <Component user={user} {...(props as any)} />;

    return onRedirecting();
  };
};

export default withPageAuthRequired;
