import {
  Link,
  SkeletonBlock,
  Spacer,
  TableBody,
  TableHead,
  TableHeadHeading,
  TableHeadSortButton,
  TableRow,
  TableTh,
  TableTitleArea,
  Text,
} from "@zapier/design-system";
import { Button } from "components/Button";
import { CurrentProjectProvider } from "lib/context/current-project-context";
import { ListItem } from "./ListItem";
import { Project, ProjectList } from "utils/trpc";
import { ActionsMenu } from "./components";
import { styled } from "lib/theme";
import React from "react";
import TemplateHandler from "components/TemplateHandler";
import Wootric from "components/Wootric";
import { config } from "@/config";
import { getHelpUrl } from "lib/route-helpers";
import { useSplitFlags } from "lib/context/split-context";

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
  margin-left: auto;
`;

export const ProjectListHeader = React.memo(function Header({
  canCreateProjects,
  selectedProjects,
  projects,
  onSelectAll,
  onClearSelection,
  onDeleteSelected,
  handleClickCreate,
  onUpgradeClick,
}: {
  canCreateProjects: boolean;
  selectedProjects: string[];
  projects: ProjectList;
  onSelectAll: VoidFunction;
  onClearSelection: VoidFunction;
  onDeleteSelected: VoidFunction;
  handleClickCreate: VoidFunction;
  onUpgradeClick: VoidFunction;
}) {
  return (
    <TableTitleArea>
      <div data-testid="projects-list-actions">
        <ActionsMenu
          selectedCount={selectedProjects.length}
          maxSelectionCount={projects?.length ?? 0}
          onSelectAll={onSelectAll}
          onClearSelection={onClearSelection}
          onDeleteSelected={onDeleteSelected}
        />
      </div>
      <Actions data-testid="project-list-header-actions">
        <Button
          href={getHelpUrl()}
          target="_blank"
          iconBefore="navHelp"
          color="tertiary"
        >
          Help
        </Button>
        {canCreateProjects ? (
          <Button iconBefore="formAdd" onClick={handleClickCreate}>
            Create
          </Button>
        ) : (
          <Button size="small" iconBefore="actionLock" onClick={onUpgradeClick}>
            Upgrade to create more Interfaces
          </Button>
        )}
      </Actions>
    </TableTitleArea>
  );
});

export const ProjectsListTableHeader = React.memo(function TableHeader({
  onSortBy,
}: {
  onSortBy: (key: "name" | "updatedAt") => void;
}) {
  return (
    <TableHead>
      <TableRow>
        <TableTh>{""}</TableTh>
        <TableTh>
          <TableHeadSortButton onClick={() => onSortBy("name")}>
            Title
          </TableHeadSortButton>
        </TableTh>
        <TableTh>
          <TableHeadSortButton onClick={() => onSortBy("name")}>
            Published page
          </TableHeadSortButton>
        </TableTh>
        <TableTh>{""}</TableTh>
        <TableTh>
          <TableHeadSortButton onClick={() => onSortBy("updatedAt")}>
            Last Opened
          </TableHeadSortButton>
        </TableTh>
        <TableTh>
          <TableHeadHeading>Access</TableHeadHeading>
        </TableTh>
        <TableTh>
          <TableHeadHeading>Owner</TableHeadHeading>
        </TableTh>
        <TableTh>{""}</TableTh>
      </TableRow>
    </TableHead>
  );
});

type TableProps = {
  projects: ProjectList;
  selectedProjects: string[];
  handleSelect: (projectId: string) => void;
};

export function ProjectsTableBody({
  projects,
  selectedProjects,
  handleSelect,
}: TableProps) {
  const [showClone] = useSplitFlags(["interfaces_clone_project"]);

  if (!projects) return null;

  return (
    <>
      <TableBody>
        {projects.map((project) => {
          return (
            <CurrentProjectProvider
              key={project.project.id}
              project={project.project as Project}
            >
              <ListItem
                projectListItem={project}
                selected={selectedProjects.includes(project.project.id)}
                handleSelect={handleSelect}
                showClone={showClone}
              />
            </CurrentProjectProvider>
          );
        })}
      </TableBody>
      <TemplateHandler />
      {config().NEXT_PUBLIC_APP_ENV === "production" && (
        <Wootric projectCount={projects?.length} />
      )}
    </>
  );
}

const ListWrapper = styled.div`
  padding-top: 22px;
  max-width: 100%;
`;

const LoadMoreButtonWrapper = styled.div`
  text-align: center;
`;

export function ProjectsListFooter({
  isLoading,
  hasNextPage,
  fetchNextPage,
}: {
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}) {
  return (
    <ListWrapper>
      {hasNextPage ? (
        <>
          <Spacer height={30} />
          <LoadMoreButtonWrapper>
            <Button onClick={() => fetchNextPage()} isLoading={isLoading}>
              Load more
            </Button>
          </LoadMoreButtonWrapper>
        </>
      ) : null}
      <Spacer height={20} />
      <Text tag="p" type="paragraph3" textAlign="center" margin="10px">
        Have a suggestion?{" "}
        <Link href="https://eap.zapier.app/interfaces" target="_blank">
          Submit your idea
        </Link>
      </Text>
    </ListWrapper>
  );
}

const Tbody = styled.tbody`
  & tr {
    &:hover {
      background: #000;
    }
  }
`;

export function ProjectsListTableLoading() {
  return (
    <Tbody>
      <tr>
        <td colSpan={6}>
          <SkeletonBlock height={120} />
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <SkeletonBlock height={120} />
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <SkeletonBlock height={120} />
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <SkeletonBlock height={120} />
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <SkeletonBlock height={120} />
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          <SkeletonBlock height={120} />
        </td>
      </tr>
    </Tbody>
  );
}
