import { useRouter } from "next/router";
import { useEffect } from "react";

const TemplateHandler = () => {
  const router = useRouter();

  useEffect(() => {
    const localTemplateKey = localStorage.getItem("createFromTemplateKey");

    if (localTemplateKey) {
      localStorage.removeItem("createFromTemplateKey");
      void router.push(`/templates?create=${localTemplateKey}`);
    }
  }, [router]);

  return null;
};

export default TemplateHandler;
