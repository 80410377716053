import {
  Icon,
  IconName,
  Shadows,
  SkeletonBlock,
  SkeletonText,
} from "@zapier/design-system";
import styled from "styled-components";

const Card = styled.li`
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: ${Shadows.elevation5};
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr 160px;
`;

interface LoadingCardProps {
  kind: "table" | "zap" | "interface" | "chatbot" | "canvas";
}

const LoadingCard = ({ kind }: LoadingCardProps) => {
  let icon: IconName = "miscBoltAltFill";

  if (kind === "chatbot") {
    icon = "navAIChatbot";
  } else if (kind === "interface") {
    icon = "navInterfaces";
  } else if (kind === "table") {
    icon = "navTables";
  } else if (kind === "canvas") {
    icon = "navCanvas";
  }

  return (
    <Card>
      <Icon name={icon} color="brand" />
      <SkeletonText lines={1} />
      <SkeletonBlock />
    </Card>
  );
};

export default LoadingCard;
