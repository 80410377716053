import React, { useState } from "react";

import styled from "styled-components";
import {
  Text,
  Icon,
  ColorsCSS as Colors,
  Spacer,
  Button,
  YouTubeVideo,
  ModalMediaContent,
  Shadows,
} from "@zapier/design-system";
import Image from "next/image";
import VideoThumbnail from "./intro-video-thumbnail.png";
import { Modal } from "components/Modal";
import { useMixpanelContext } from "observability/mixpanel";

const Wrapper = styled.div`
  margin: 20px 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media (max-width: 640px) {
    flex-direction: column;
    gap: 28px;
  }
`;

const Bottom = styled.div`
  display: flex;
  gap: 35px;
  @media (max-width: 850px) {
    flex-direction: column;
    gap: 28px;
  }
`;

const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 50px;
  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const HeaderText = styled.div`
  max-width: 800px;
  margin-bottom: 20px;
  padding-top: 2px;
  text-align: left;
`;

const Highlights = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Highlight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const VideoContent = styled.div`
  padding: 15px 20px;
  border-left: 1px solid ${Colors.neutral400};
  transition: border-left-color 150ms;
`;

const VideoDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

const ThumbnailWrapper = styled.div`
  flex-basis: 170px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const VideoWrapper = styled.button`
  appearance: none;
  width: 100%;
  max-width: 520px;
  max-height: 102px;
  background: ${Colors.neutral200};
  border: 1px solid ${Colors.neutral400};
  border-radius: 3px;
  display: flex;
  text-align: left;
  transition:
    background 150ms,
    border-color 150ms,
    box-shadow 150ms;
  &:hover,
  &:focus {
    background: ${Colors.blue100};
    border-color: ${Colors.blue600};
    box-shadow: ${Shadows.elevation5};
    ${VideoContent} {
      border-left-color: ${Colors.blue600};
    }
  }
`;

interface HeaderProps {
  startBlank: () => void;
}

export default function Header({ startBlank }: HeaderProps) {
  const { track: mixpanelTrack } = useMixpanelContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleVideoClick = () => {
    mixpanelTrack("Clicked Intro Video Thumbnail", {
      component: "Header",
    });
    setShowModal(true);
  };

  return (
    <>
      <Wrapper>
        <Top>
          <div>
            <Title>
              <Icon color="brand" name="navInterfaces" size={35} />
              <Text type="pageHeader5" tag="h2">
                Interfaces
              </Text>
            </Title>
            <MobileTitle>
              <Icon color="brand" name="navInterfaces" size={20} />
              <Text type="pageHeader7" tag="h2">
                Interfaces
              </Text>
            </MobileTitle>
          </div>
          <div>
            <Button
              onClick={startBlank}
              color="tertiary"
              size="xsmall"
              iconBefore="formAdd"
            >
              Start blank
            </Button>
          </div>
        </Top>
        <Bottom>
          <div>
            <HeaderText>
              <Text type="paragraph3" tag="p">
                Create forms, web pages, and apps to power your
                business-critical workflows that seamlessly connect to 6,000
                apps.
              </Text>
            </HeaderText>
            <Highlights>
              <Highlight>
                <Icon color="blue500" size={22} name="miscBoltFill" />
                <Text type="smallPrint1Medium">Free tasks</Text>
              </Highlight>
              <Highlight>
                <Icon color="blue500" size={22} name="actionLockFill" />
                <Text type="smallPrint1Medium">Access control</Text>
              </Highlight>
              <Highlight>
                <Icon color="blue500" size={22} name="miscColorFill" />
                <Text type="smallPrint1Medium">Custom branding</Text>
              </Highlight>
            </Highlights>
          </div>
          <VideoWrapper onClick={handleVideoClick}>
            <ThumbnailWrapper>
              <Image
                src={VideoThumbnail}
                alt="Intro to Zapier video thumbnail"
              />
            </ThumbnailWrapper>
            <VideoContent>
              <VideoDescription>
                <Text type="smallPrint1">
                  "Welcome to Zapier Interfaces, the easiest way to create
                  custom web pages for your..."
                </Text>
              </VideoDescription>
              <Spacer height={10} />
              <Text type="smallPrint1Medium">1:13</Text>
            </VideoContent>
          </VideoWrapper>
        </Bottom>
      </Wrapper>
      {showModal ? (
        <Modal onClosed={() => setShowModal(false)}>
          <ModalMediaContent>
            <YouTubeVideo
              title="Interfaces intro video"
              youTubeId="Nt2v-ju1nls"
              autoplay={true}
            />
          </ModalMediaContent>
        </Modal>
      ) : null}
    </>
  );
}
