import { ZslTemplate } from "server/zsl/templates/types";

const tableTemplate: ZslTemplate = {
  name: "Table",
  chatbots: [],
  zaps: [],
  interfaces: [
    {
      id: "_INTERFACE_1",
      name: "Table",
      homepage: "_PAGE_1",
      pages: [
        {
          id: "_PAGE_1",
          name: "Page",
          navigationEnabled: true,
          blocks: [
            {
              type: "table-listing-block",
              config: {
                id: "_BLOCK_1",
                title: "My table",
                tableId: "${{ tables._TABLE_1 }}",
                hiddenFields: [],
                columnOrder: [
                  "${{ tables._TABLE_1._FIELD_1 }}",
                  "${{ tables._TABLE_1._FIELD_2 }}",
                  "${{ tables._TABLE_1._FIELD_3 }}",
                  "${{ tables._TABLE_1._FIELD_4 }}",
                ],
                canCreateRecords: true,
                canUpdateRecords: true,
                canDeleteRecords: true,
                canSearchRecords: true,
                style: {
                  width: "wide",
                  alignment: "center",
                },
              },
            },
          ],
        },
      ],
    },
  ],
  tables: [
    {
      id: "_TABLE_1",
      name: "Contact List",
      fields: [
        {
          id: "_FIELD_1",
          name: "Last name",
          type: "string",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            description: "",
          },
        },
        {
          id: "_FIELD_2",
          name: "First name",
          type: "string",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            description: "",
          },
        },
        {
          id: "_FIELD_3",
          name: "Email",
          type: "email",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            icon: "actionEmail",
            alignment: "left",
            description: "",
          },
        },
        {
          id: "_FIELD_4",
          name: "Phone",
          type: "phone_number",
          options: {
            indexed: false,
            required: false,
            read_only: false,
            converted_from: "string",
            converted_from_version: 7,
            icon: "miscHeadset",
            alignment: "right",
            phone_number_format: "phone_number_format_1",
            description: "",
          },
        },
      ],
    },
  ],
  parameters: [],
};

export default tableTemplate;
