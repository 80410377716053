import {
  Avatar,
  AvatarsWithText,
  AvatarText,
  ColorName,
  ColorsCSS as Colors,
  FloatingBox,
  Icon,
  IconButton,
  IconName,
  Menu,
  MenuItem,
  Shadows,
  Spacer,
  Text,
  TooltipWrapper,
} from "@zapier/design-system";
import { styled } from "lib/theme";
import Image from "next/legacy/image";
import { Fragment, ReactNode, useState } from "react";
import { ProjectListProject } from "utils/trpc";
import fakeGraphSrc from "./fake-graph.png";

export const Wrapper = styled.a`
  height: 172px;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: stretch;

  background: ${Colors.neutral100};
  border: 1px solid ${Colors.neutral400};
  border-radius: 6px;

  overflow: hidden;

  color: ${Colors.neutral800};
  text-decoration: none;

  transition: all 0.14159s;

  &:hover {
    border: 1px solid ${Colors.blue600};
    box-shadow: ${Shadows.elevation5};
    color: ${Colors.blue600};
  }
`;

export const ThumbnailWrapper = styled.div`
  width: 100px;
  border-right: 1px solid ${Colors.neutral400};
`;

export const Body = styled.div`
  padding: 20px 30px 30px 30px;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  column-gap: 30px;
`;

const Detail = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  row-gap: 10px;
`;

const DetailLabel = styled.div``;

const DetailBody = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

export const BorderedBox = styled.div`
  border: 1px solid ${Colors.neutral400};
  border-radius: 5px;
`;

export const CreatorAvatarWrapper = styled(BorderedBox)`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconCountBoxWrapper = styled(BorderedBox)`
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
`;

const IconCountBoxLeft = styled.div`
  padding: 0 12px 0 10px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 8px;
`;

const IconCountBoxRight = styled.div`
  padding: 0 8px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Colors.neutral200};
  border-left: 1px solid ${Colors.neutral400};
`;

const ActionsMenuWrapper = styled.div`
  display: flex;
  width: 80px;
  height: 44px;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
`;

const MenuWrapper = styled.div`
  position: relative;
`;

export function IconCountBox(
  props: {
    count: number | undefined;
    isLoading?: boolean;
  } & (
    | {
        iconName: IconName;
        iconSize?: number;
      }
    | { renderIcon: (color: ColorName) => ReactNode }
  )
) {
  const neutralColor = "neutral600";

  const renderIcon = (active: boolean) => {
    const color = active ? "brand" : neutralColor;

    if ("iconName" in props) {
      return (
        <Icon name={props.iconName} size={props.iconSize ?? 24} color={color} />
      );
    }

    return props.renderIcon(color);
  };

  if (props.isLoading)
    return (
      <TooltipWrapper content="One moment..." position="north">
        {({ childProps }) => (
          <IconCountBoxWrapper {...childProps}>
            <IconCountBoxLeft>
              {renderIcon(false)}
              <Icon name="navMoreHoriz" color={neutralColor} size={20} />
            </IconCountBoxLeft>
          </IconCountBoxWrapper>
        )}
      </TooltipWrapper>
    );

  if (props.count)
    return (
      <IconCountBoxWrapper>
        <IconCountBoxLeft>
          {renderIcon(true)}
          <Text type="paragraph3Semibold">{props.count}</Text>
        </IconCountBoxLeft>
        <IconCountBoxRight>
          <Image src={fakeGraphSrc} height={20} width={68} alt="" />
        </IconCountBoxRight>
      </IconCountBoxWrapper>
    );

  return (
    <IconCountBoxWrapper>
      <IconCountBoxLeft>{renderIcon(false)}</IconCountBoxLeft>
    </IconCountBoxWrapper>
  );
}

export function CreatorAvatar(props: {
  creator: ProjectListProject["creator"];
}) {
  const fullName = [props.creator.firstName, props.creator.lastName].join(" ");

  return <Avatar name={fullName} url={props.creator.avatarUrl} size={25} />;
}

export function CreatorAvatarWithName(props: {
  creator: ProjectListProject["creator"];
}) {
  const fullName = [props.creator.firstName, props.creator.lastName].join(" ");

  return (
    <AvatarsWithText
      avatars={<Avatar name={fullName} url={props.creator.avatarUrl} />}
      size={25}
    >
      <Text type="smallPrint1">
        <AvatarText kind="light">by</AvatarText>{" "}
        <AvatarText kind="heavy">{fullName}</AvatarText>
      </Text>
    </AvatarsWithText>
  );
}

export type DetailsData = { label: string; body: ReactNode }[];

export function Details(props: { details: DetailsData }) {
  const renderItems = () => {
    return props.details.map((detail, i) => (
      <Detail key={i}>
        <DetailLabel>
          <Text type="smallPrint1Medium" color="neutral800">
            {detail.label}
          </Text>
        </DetailLabel>
        <DetailBody>{detail.body}</DetailBody>
      </Detail>
    ));
  };

  return <Fragment>{renderItems()}</Fragment>;
}

const getBulkSelectIcon = (
  selectedCount: number,
  maxSelectionCount: number
) => {
  if (selectedCount === maxSelectionCount && maxSelectionCount > 0) {
    return "formCheckSquare";
  } else if (selectedCount !== 0) {
    return "formDashSquare";
  }
  return "formBlank";
};

export function ActionsMenu(props: {
  onSelectAll: () => void;
  onClearSelection: () => void;
  onDeleteSelected: () => void;
  selectedCount: number;
  maxSelectionCount: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ActionsMenuWrapper>
      <IconButton
        ariaLabel={
          props.selectedCount > 0
            ? "Clear Interfaces selection"
            : "Select all Interfaces"
        }
        icon={getBulkSelectIcon(props.selectedCount, props.maxSelectionCount)}
        onClick={() => {
          if (props.selectedCount > 0) props.onClearSelection();
          else props.onSelectAll();
        }}
        size="medium"
        color="icon-tertiary"
      />
      <MenuWrapper>
        <IconButton
          ariaLabel={"Actions"}
          icon="arrowSmallDown"
          size="medium"
          color="icon-tertiary"
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen ? (
          <FloatingBox
            align="left"
            onClickOutside={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            <div
              data-testid="projects-list-actions-menu"
              style={{
                padding: 10,
                width: 200,
              }}
            >
              {props.selectedCount > 0 && (
                <div style={{ padding: 10 }}>
                  <Text type="paragraph3Semibold" color="neutral800">
                    {props.selectedCount} selected
                  </Text>
                  <Spacer height={10} />
                  <hr></hr>
                </div>
              )}
              <Menu ariaLabel="Available actions">
                {props.selectedCount !== props.maxSelectionCount && (
                  <MenuItem
                    onClick={() => {
                      props.onSelectAll();
                      setIsOpen(false);
                    }}
                  >
                    Select all
                  </MenuItem>
                )}
                {props.selectedCount > 0 && (
                  <>
                    <MenuItem
                      onClick={() => {
                        props.onClearSelection();
                        setIsOpen(false);
                      }}
                    >
                      Clear selection
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.onDeleteSelected();
                        setIsOpen(false);
                      }}
                    >
                      Delete Selected
                    </MenuItem>
                  </>
                )}
              </Menu>
            </div>
          </FloatingBox>
        ) : null}
      </MenuWrapper>
    </ActionsMenuWrapper>
  );
}
