import { Button, Icon, Text } from "@zapier/design-system";
import InterfacesText from "components/InterfacesText";
import { getZapierBaseUrl } from "lib/route-helpers";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  background: var(--zds-colors-neutral-100);
  border: 1px solid var(--zds-colors-neutral-400);
  border-radius: 3px;
  width: 260px;
`;

const Description = styled(InterfacesText)`
  margin-top: 10px;
`;

const Price = styled(InterfacesText)`
  margin-top: 15px;
`;

const PriceSubText = styled(InterfacesText)`
  margin: 5px 0 24px;
`;

const FeatureTitle = styled(InterfacesText)`
  margin-top: 32px;
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  margin-top: 10px;
`;

export type PricingButtonText =
  | "Sign up today"
  | "Downgrade"
  | "Manage plan"
  | "Upgrade"
  | "Buy now";

const buttonColors: Record<PricingButtonText, "primary" | "secondary"> = {
  ["Downgrade"]: "secondary",
  ["Manage plan"]: "secondary",
  ["Sign up today"]: "primary",
  ["Upgrade"]: "primary",
  ["Buy now"]: "primary",
};

interface PricingCardProps {
  description: string;
  featuresTitle?: string;
  includes?: string[];
  isCurrentPlan?: boolean;
  price: string;
  priceSubText?: string;
  title: string;
  buttonText: PricingButtonText;
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const PricingCard: React.FC<PricingCardProps> = ({
  description,
  featuresTitle = "Includes:",
  includes,
  isCurrentPlan,
  price,
  priceSubText,
  title,
  buttonText,
  href,
  target,
}) => {
  const computedButtonText: PricingButtonText = isCurrentPlan
    ? "Manage plan"
    : buttonText;

  return (
    <Wrapper>
      <Text type="sectionHeader3Bold">{title} </Text>
      <Description textType="smallPrint1">{description}</Description>
      <Price textType="pageHeader7">{price}</Price>
      {priceSubText ? (
        <PriceSubText textType="smallPrint1">{priceSubText}</PriceSubText>
      ) : null}
      <Button
        color={buttonColors[computedButtonText] ?? "primary"}
        isFullWidth
        href={
          isCurrentPlan ? `${getZapierBaseUrl()}/app/planbuilder/add-ons` : href
        }
        target={target}
      >
        {computedButtonText}
      </Button>
      <FeatureTitle textType="smallPrint1Semibold">
        {featuresTitle}
      </FeatureTitle>
      {includes?.map((item, index) => (
        <Feature key={index}>
          <Icon color="success400" size={24} name="formCheck" />
          <Text type="paragraph3Medium">{item}</Text>
        </Feature>
      ))}
    </Wrapper>
  );
};

export default PricingCard;
