import { Text, LabeledRadio } from "@zapier/design-system";
import { ZapRemovalOption } from "server/schemas/zaps";

export function ZapDeletePrompt(props: {
  prompt: string;
  value: ZapRemovalOption;
  onChange: (value: ZapRemovalOption) => void;
}) {
  return (
    <fieldset>
      <Text tag="legend" type="paragraph3Medium" color="neutral700">
        {props.prompt}
      </Text>
      <LabeledRadio
        name="radio"
        value="disable-zap"
        tabIndex={0}
        checked={props.value === "disable-zap"}
        onChange={() => props.onChange("disable-zap")}
      >
        Keep Zap(s) but turn off
      </LabeledRadio>
      <LabeledRadio
        name="radio"
        value="delete-zaps"
        tabIndex={0}
        checked={props.value === "delete-zap"}
        onChange={() => props.onChange("delete-zap")}
      >
        Delete Zap(s)
      </LabeledRadio>
    </fieldset>
  );
}
