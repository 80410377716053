import { useCallback, useEffect, useMemo, useState } from "react";

import { Spacer, Table, Text } from "@zapier/design-system";
import { useUser } from "lib/context/user-context";
import { styled } from "lib/theme";

import {
  UniversalFooter,
  UniversalNavProvider,
} from "@zapier/universal-layout";
import { useModalBulkDeleteProjects } from "components/ModalBulkDeleteProjects";
import { Spinner } from "components/Spinner";
import { TablesProvider } from "lib/context/tables-context";
import { useAccountPlan } from "lib/hooks/useAccountPlan";
import {
  getInterfacesBillingUrl,
  getInterfacesUrl,
  getZapierBaseUrl,
} from "lib/route-helpers";
import { useTrackingContext } from "observability";
import { MixpanelEvents, useMixpanelContext } from "observability/mixpanel";
import { trpc } from "utils/trpc";
import { ZinniaStyleSelectionModifier } from "utils/zinniaHelpers";
import { ModalCreateProject } from "../ModalCreateProject";
import { Banner } from "./Banner";
import {
  ProjectListHeader,
  ProjectsListFooter,
  ProjectsListTableHeader,
  ProjectsListTableLoading,
  ProjectsTableBody,
} from "./ProjectsList";
import { Sidebar } from "./Sidebar/Sidebar";
import { ZeroState } from "./ZeroState";
import { useSplitFlags } from "lib/context/split-context";
import { keepPreviousData } from "@tanstack/react-query";

const ZeroStateWrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 50px;
  min-height: calc(100vh - 176px);
  @media screen and (max-width: 960px) {
    padding: 40px 24px;
  }
`;

const Wrapper = styled.div`
  min-height: calc(100vh - 176px);
  display: flex;
  gap: 20px 40px;
  padding: 10px 40px 50px;
  max-width: 2300px;
  margin: auto;
`;

const NoSearchResults = styled.div`
  padding: 32px;
  text-align: center;
`;

const MainWrapper = styled.div`
  flex: 3 0 0%;
  min-width: 400px;
`;

const ListWrapper = styled.div<{ $isLoading: boolean }>`
  ${({ $isLoading }) => $isLoading && `pointer-events: none; opacity: 0.5; }`};
  & table${ZinniaStyleSelectionModifier} {
    tbody${ZinniaStyleSelectionModifier} {
      tr {
        td {
          padding: 8px 10px;
        }
      }
    }
  }
`;

export function Projects() {
  const [showTemplatesAtTopLevelTreatmentOn] = useSplitFlags([
    "interfaces_enable-top-level-templates",
  ]);

  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState<{
    key: "updatedAt" | "name";
    order: "desc" | "asc";
  }>({
    key: "updatedAt",
    order: "desc",
  });

  const urlParams = new URLSearchParams(window.location.search);

  const [sharing, setSharing] = useState<"all" | "my" | "shared">("all");
  const [createModalIndicator, setCreateModalIndicator] = useState<
    string | boolean
  >(urlParams.get("modalOpen") === "create");
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const { emitUserInteractionEvent, emitBuilderInteractionEvent } =
    useTrackingContext();
  const { track: mixpanelTrack } = useMixpanelContext();

  useEffect(() => {
    setSelectedProjects([]);
  }, [sharing]);

  const [confirmDelete, renderBulkDeleteModal] = useModalBulkDeleteProjects(
    selectedProjects,
    () => setSelectedProjects([])
  );

  const { user } = useUser();

  if (!user) throw new Error("User not returned by queries.");

  const accountId = user.currentAccount;
  if (!accountId)
    throw new Error("Unable to get currently selected Zapier account");

  const projectsQuery = trpc.projects.listListingPage.useInfiniteQuery(
    { accountId, limit: 6, query, sortBy, sharing },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      placeholderData: keepPreviousData,
      throwOnError: false,
      meta: { noToast: true },
    }
  );

  const { isFree } = useAccountPlan(user);

  const isUpgraded = !isFree();

  const [projectsCount] = trpc.projects.count.useSuspenseQuery();

  if (projectsQuery.error) throw new Error(projectsQuery.error.message);

  const projects = useMemo(
    () => projectsQuery.data?.pages.flatMap((page) => page.projects),
    [projectsQuery.data?.pages]
  );

  const projectIds = useMemo(() => {
    return projects?.map((project) => project.project.id);
  }, [projects]);

  const canCreateProjects = useMemo(() => {
    if (user.isStaff) return true;
    return (projects?.length ?? 0) < (user.accountProjectLimit ?? 0);
  }, [projects?.length, user.accountProjectLimit, user.isStaff]);

  const isLoading = projectsQuery.isLoading;

  const onSortBy = useCallback(
    (sortKey: "updatedAt" | "name") => {
      if (sortKey === sortBy.key) {
        setSortBy((prev) => ({
          ...prev,
          order: prev.order === "asc" ? "desc" : "asc",
        }));
      } else {
        setSortBy({ key: sortKey, order: "desc" });
      }
    },
    [sortBy.key]
  );

  const handleSelectProject = useCallback(
    (projectId: string) => {
      if (selectedProjects.includes(projectId)) {
        setSelectedProjects((prev) => prev.filter((id) => id !== projectId));
      } else {
        setSelectedProjects((prev) => [...prev, projectId]);
      }
    },
    [selectedProjects]
  );

  const handleClickCreate = () => {
    mixpanelTrack(MixpanelEvents.CLICKED_CREATE_PROJECT, {
      reactComponent: "Projects",
    });

    emitUserInteractionEvent({
      interaction_raw_text: "Create",
      event_action: "click",
      option_selected: "clicked create new project",
      experiment_name: "interfaces_enable-top-level-templates",
      experiment_flag: showTemplatesAtTopLevelTreatmentOn,
    });

    setCreateModalIndicator(true);
  };

  const onUpgradeClick = () => {
    const url = getInterfacesBillingUrl(window.location.href);

    window.open(url, "_blank");

    emitBuilderInteractionEvent({
      interaction_goal: "projectLimit upgrade button clicked",
      event_action: "click",
    });
  };

  const renderBody = () => {
    if (isLoading && !projectIds) {
      return <ProjectsListTableLoading />;
    }

    if (query && !projects?.length) {
      return (
        <tr>
          <td colSpan={6}>
            <NoSearchResults>
              <Text type="sectionHeader3">No search results</Text>
              <Spacer height={10} />
              <Text type="paragraph1">Try a different search</Text>
            </NoSearchResults>
          </td>
        </tr>
      );
    }

    if (projects?.length) {
      return (
        <ProjectsTableBody
          projects={projects}
          selectedProjects={selectedProjects}
          handleSelect={handleSelectProject}
        />
      );
    }

    return (
      <tr>
        <td colSpan={6}>
          <NoSearchResults>
            <Text type="paragraph3Bold">No results</Text>
          </NoSearchResults>
        </td>
      </tr>
    );
  };

  const renderZeroState = () => {
    return (
      <TablesProvider accountId={accountId}>
        <ZeroStateWrapper>
          <ZeroState
            setShowCreateModal={setCreateModalIndicator}
            onClick={handleClickCreate}
          />
        </ZeroStateWrapper>
      </TablesProvider>
    );
  };

  if (projectsQuery.isInitialLoading)
    return (
      <ZeroStateWrapper>
        <Spinner />
      </ZeroStateWrapper>
    );

  return (
    <>
      {projects &&
      projects.length === 0 &&
      query === "" &&
      sharing === "all" ? (
        renderZeroState()
      ) : (
        <Wrapper>
          <Sidebar
            projectsCount={projectsCount}
            query={query}
            setQuery={setQuery}
            sharing={sharing}
            setSharing={setSharing}
          />
          <MainWrapper>
            <Banner
              canCreateProjects={canCreateProjects}
              isUpgraded={isUpgraded}
            />
            <Spacer height={20} />
            <ProjectListHeader
              canCreateProjects={canCreateProjects}
              selectedProjects={selectedProjects}
              projects={projects ?? []}
              onSelectAll={() => setSelectedProjects(projectIds ?? [])}
              onClearSelection={() => setSelectedProjects([])}
              onDeleteSelected={confirmDelete}
              handleClickCreate={handleClickCreate}
              onUpgradeClick={onUpgradeClick}
            />
            <Spacer height={10} />
            <ListWrapper
              $isLoading={projectsQuery.isLoading}
              data-testid="projects-list-table"
            >
              <Table kind="standard">
                <ProjectsListTableHeader onSortBy={onSortBy} />
                {renderBody()}
              </Table>
              <ProjectsListFooter
                hasNextPage={!!projectsQuery.hasNextPage}
                isLoading={projectsQuery.isFetchingNextPage}
                fetchNextPage={() => projectsQuery.fetchNextPage()}
              />
            </ListWrapper>
          </MainWrapper>
        </Wrapper>
      )}
      {createModalIndicator ? (
        <ModalCreateProject
          accountId={accountId}
          onClosed={() => setCreateModalIndicator(false)}
          templateKey={
            typeof createModalIndicator === "string"
              ? createModalIndicator
              : null
          }
          user={user}
        />
      ) : null}
      {renderBulkDeleteModal()}
      <UniversalNavProvider
        currentUrl={getInterfacesUrl()}
        urlPathPrefix={getZapierBaseUrl()}
        apiHostname={getZapierBaseUrl()}
      >
        <UniversalFooter showSimplifiedFooter />
      </UniversalNavProvider>{" "}
    </>
  );
}
