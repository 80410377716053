import { SplitFactory } from "@splitsoftware/splitio-react";
import { UniversalLayout, ZapierSession } from "@zapier/universal-layout";
import AppProvider from "components/AppProvider";
import { IntercomScript } from "components/IntercomScript";
import MainLayout from "components/MainLayout";
import { Projects } from "components/Projects";
import { UpgradedModalProvider } from "lib/context/upgrade-modal-provider";
import { useUser } from "lib/context/user-context";
import WithPageAuthRequired, {
  WithPageAuthRequiredProps,
} from "lib/hooks/withPageAuthRequired";
import { getZapierBaseUrl } from "lib/route-helpers";
import Head from "next/head";
import { isProduction } from "utils/environment";

function ProjectsPage() {
  const { user } = useUser();

  const sessionData: ZapierSession = {
    customUserId: user?.zapierId ?? 0,
    isLoggedIn: true,
    email: user?.email ?? "",
    currentAccountId: user?.currentAccount,
    /**
     * Some UI elements from UniversalLayout require the current user role to be set.
     * See https://zapier.slack.com/archives/C03DHD00CT1/p1724033390002109
     */
    currentUserRole: user?.currentUserRole,
    hasSeenNotifications: {},
  };

  const tablesSplitConfig: SplitIO.IBrowserSettings = {
    core: {
      // NOTE: this is the auth key for TABLES. If we add a client-side flag for Interfaces in
      // the future, we'll need to add a second config like we do for server-side splits
      authorizationKey:
        process.env.NEXT_PUBLIC_TABLES_SPLIT_IO_CLIENT_AUTH_KEY ?? "",
      key: (user?.zapierId ?? "").toString(),
    },
  };

  const currentUrl = isProduction
    ? "https://interfaces.zapier.com/interfaces"
    : "https://interfaces.zapier-staging.com/interfaces";

  return (
    <MainLayout>
      <Head>
        <title>Interfaces</title>
      </Head>

      <SplitFactory config={tablesSplitConfig}>
        <UniversalLayout
          apiHostname={getZapierBaseUrl()}
          currentUrl={currentUrl}
          mode="in-app"
          renderAlertBanner={() => null}
          renderFooter={() => null}
          sessionData={sessionData}
          trackEvent={() => null}
          urlPathPrefix={getZapierBaseUrl()}
        >
          <UpgradedModalProvider>
            <Projects />
          </UpgradedModalProvider>
          <IntercomScript />
        </UniversalLayout>
      </SplitFactory>
    </MainLayout>
  );
}

const AuthedProjectsPage =
  WithPageAuthRequired<WithPageAuthRequiredProps>(ProjectsPage);

export default function ProjectsPageWithProvider(
  props: WithPageAuthRequiredProps
) {
  return (
    <AppProvider
      interfacesTheme={undefined}
      projectAppearance={undefined}
      user={props.user}
      projectId={undefined}
    >
      <AuthedProjectsPage {...props} />
    </AppProvider>
  );
}
