import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Spacer, Text } from "@zapier/design-system";

import { Dispatch, SetStateAction } from "react";
import TemplateHandler from "components/TemplateHandler";
import { useMixpanelContext } from "observability/mixpanel";
import { TemplateGrid } from "components/TemplateGrid";
import { SimpleCard } from "./SimpleCard";
import FormImage from "./form.png";
import TableImage from "./table.png";
import KanbanImage from "./kanban.png";
import Header from "./Header";
import formTemplate from "./SimpleTemplates/form";
import kanbanTemplate from "./SimpleTemplates/kanban";
import { useRouter } from "next/router";
import { LoadingModal } from "components/LoadingModal";
import tableTemplate from "./SimpleTemplates/table";
import { ZslTemplate } from "server/zsl/templates/types";
import { trpc } from "utils/trpc";
import { projectPath } from "lib/route-helpers";

const SimpleCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin: 25px 0 60px;
  grid-gap: 20px;
`;

interface ZeroStateProps {
  className?: string;
  setShowCreateModal: Dispatch<SetStateAction<string | boolean>>;
  onClick: () => void;
}

export function ZeroState({ onClick, setShowCreateModal }: ZeroStateProps) {
  const { track: mixpanelTrack } = useMixpanelContext();
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  const { mutateAsync: executeTemplate } =
    trpc.projects.executeTemplate.useMutation();

  useEffect(() => {
    mixpanelTrack("Viewed Zero State", {
      reactComponent: "ZeroState",
      reactComponentVersion: "April 2024",
    });
  }, [mixpanelTrack]);

  const handleClick = () => {
    mixpanelTrack("Opened Create Modal", {
      reactComponent: "ZeroState",
      reactComponentVersion: "April 2024",
    });
    onClick();
  };

  const handleCreate = (templateName: string) => {
    mixpanelTrack("Created Template", {
      reactComponent: "ZeroState",
      template: templateName,
      reactComponentVersion: "April 2024",
    });
    setShowCreateModal(templateName);
  };

  const handleStartSimpleClick = async (zsl: ZslTemplate) => {
    setLoading(true);

    mixpanelTrack("Clicked Start Simple", {
      reactComponent: "ZeroState",
      template: zsl.name,
      reactComponentVersion: "April 2024",
    });

    const response = await executeTemplate({
      zsl,
    });

    const projectId = Object.keys(response.data?.interfaces)[0];

    if (projectId) {
      const path = projectPath({ projectId: projectId });
      return router.push(path);
    }

    if (response.redirectTo) {
      return router.push(response.redirectTo);
    }

    setLoading(false);
  };

  return (
    <section data-testid="projects-zero-state">
      <Header startBlank={handleClick} />
      <Spacer height={20} />
      <Text type="subHeader5Medium">Start simple</Text>
      <SimpleCards>
        <SimpleCard
          title="Form"
          image={FormImage}
          onClick={() => handleStartSimpleClick(formTemplate)}
          triggers={["Form is submitted"]}
        />
        <SimpleCard
          title="Table"
          image={TableImage}
          onClick={() => handleStartSimpleClick(tableTemplate)}
          triggers={[
            "New record is added",
            "Record is updated",
            "Zap button is clicked",
          ]}
        />
        <SimpleCard
          title="Kanban"
          image={KanbanImage}
          onClick={() => handleStartSimpleClick(kanbanTemplate)}
          triggers={[
            "Card changes columns",
            "New card is created",
            "Card details are updated",
          ]}
        />
      </SimpleCards>
      <Text type="subHeader5Medium">Templates</Text>
      <TemplateGrid handleCreate={handleCreate} />
      <TemplateHandler />
      {loading ? <LoadingModal /> : null}
    </section>
  );
}
