import { Text } from "@zapier/design-system";
import { styled } from "lib/theme";
import { removeHttpFromUrl } from "lib/utils";
import { ZinniaStyleSelectionModifier } from "utils/zinniaHelpers";
import { Icon } from "@zapier/design-system";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 5px;
  align-items: center;
`;

const PublishedPageLink = styled.a`
  all: unset;
  display: block;
  height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  & span${ZinniaStyleSelectionModifier} {
    max-width: 100%;
    overflow: hidden;

    p${ZinniaStyleSelectionModifier} {
      max-width: 30ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 1450px) {
    max-width: 200px;
  }

  @media screen and (max-width: 1350px) {
    max-width: 120px;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

type ProjectPathLabelProps = {
  projectUrl: string;
};

export function ProjectPathLabel({ projectUrl }: ProjectPathLabelProps) {
  return (
    <Wrapper>
      <Icon name="actionLink" size={20} isBlock />
      <PublishedPageLink
        href={projectUrl}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        <Text tag="span" type="smallPrint1Medium" textAlign="left">
          {removeHttpFromUrl(projectUrl)}
        </Text>
      </PublishedPageLink>
    </Wrapper>
  );
}
