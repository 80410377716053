import { Modal } from "@zapier/design-system";
import { LoadingContent } from "components/ModalCreateProject/LoadingContent";

export function LoadingModal() {
  // Modal required `onClosed` but don't allow closing

  return (
    <Modal onClosed={() => false} canClose={false}>
      <LoadingContent />
    </Modal>
  );
}
