import {
  Dropdown,
  Icon,
  ColorsCSS as Colors,
  Text,
} from "@zapier/design-system";
import SearchInput from "components/SearchInput";
import type { Category, Role } from "lib/contentful/lib/client";
import React from "react";
import styled from "styled-components";

interface FilterBarProps {
  category: string;
  categories: Category[];
  setCategory: (category: string) => void;
  role: string;
  roles: Role[];
  setRole: (role: string) => void;
  query: string;
  setQuery: (query: string) => void;
}

const Top = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: minmax(200px, 300px) 1fr minmax(200px, 300px);
  margin-bottom: 24px;
  margin-top: 20px;
`;

const Categories = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 10px 4px;
  flex-flow: row wrap;
`;

interface CategoryProps {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const CategoryWrapper = styled.button<{
  $active: boolean;
}>`
  appearance: none;
  border-radius: 5px;
  padding: 6px 12px;
  ${({ $active }) =>
    $active &&
    `
  color: ${Colors.blue100};
  background-color: ${Colors.blue100};
  `}
  &:hover, &:focus {
    background-color: ${Colors.blue100};
  }
  &:focus {
    outline: 2px solid ${Colors.blue600};
  }
`;

const Category = ({ active, children, onClick }: CategoryProps) => (
  <CategoryWrapper $active={active} onClick={onClick}>
    <Text color={active ? "blue600" : "black"} type="smallPrint1">
      {children}
    </Text>
  </CategoryWrapper>
);

const FilterBar = ({
  category,
  categories,
  setCategory,
  role,
  roles,
  setRole,
  query,
  setQuery,
}: FilterBarProps) => {
  const handleCategoryChange = (category: string) => {
    setCategory(category);
    setRole("");
  };

  const handleRoleChange = (role: string) => {
    setRole(role);
    setCategory("popular");
  };

  const roleItems = roles.map((role) => ({
    label: role.name,
    value: role.slug,
  }));

  const selectedRole = roleItems.find((r) => r.value === role);

  return (
    <>
      <Top>
        <Dropdown
          placeholder="Filter by role"
          ariaLabel="Filter by role"
          selectedItem={selectedRole}
          onSelect={({ value }) => handleRoleChange(value)}
          items={roleItems}
          renderIcon={() => <Icon name="personCircleFill" size={20} />}
        />
        <div />
        <SearchInput
          value={query}
          handleChange={setQuery}
          placeholder="Search for a template"
        />
      </Top>
      <Categories>
        <Category
          active={category === "popular"}
          onClick={() => handleCategoryChange("popular")}
        >
          All
        </Category>
        {categories.map((c) => (
          <Category
            key={c.slug}
            active={category === c.slug}
            onClick={() => handleCategoryChange(c.slug)}
          >
            {c.name}
          </Category>
        ))}
      </Categories>
    </>
  );
};

export default FilterBar;
