import { FloatingBox } from "@zapier/design-system";
import { styled } from "lib/theme";
import { Menu, MenuProps } from "./Menu";
import { ComponentProps } from "react";

const MenuPaddingWrapper = styled.div`
  padding: 5px;
`;

type FloatingBoxProps = Pick<
  ComponentProps<typeof FloatingBox>,
  | "position"
  | "onClickOutside"
  | "align"
  | "minWidth"
  | "intersectHeight"
  | "hasWhiteBackground"
  | "maxWidth"
>;

export type FloatingMenuProps = FloatingBoxProps & MenuProps;

export function FloatingMenu({
  ariaLabel,
  menuItems,
  stopClickPropagation,
  ...floatingBoxProps
}: FloatingMenuProps) {
  return (
    <FloatingBox {...floatingBoxProps}>
      <MenuPaddingWrapper>
        <Menu
          ariaLabel={ariaLabel}
          menuItems={menuItems}
          stopClickPropagation={stopClickPropagation}
        />
      </MenuPaddingWrapper>
    </FloatingBox>
  );
}
