import axios, { AxiosError } from "axios";

export function getAxiosErrorMessage(error: unknown): string | undefined {
  if (axios.isAxiosError(error)) {
    return (
      (error as AxiosError<{ error?: string }>).response?.data.error ??
      error.message
    );
  }

  return undefined;
}
