import { createContext, useContext, useMemo, useState } from "react";
import { ModalUpgradeUser } from "components/ModalUpgradeUser";

const UpgradeModalContext = createContext<{
  openUpgradeModal: () => void;
  closeUpgradeModal: () => void;
}>({
  openUpgradeModal: () => {
    console.error("Not implemented");
  },
  closeUpgradeModal: () => {
    console.error("Not implemented");
  },
});

export function UpgradedModalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const contextValue = useMemo(() => {
    const openUpgradeModal = () => {
      setIsOpen(true);
    };
    const closeUpgradeModal = () => setIsOpen(false);
    return {
      openUpgradeModal,
      closeUpgradeModal,
    };
  }, []);

  return (
    <UpgradeModalContext.Provider value={contextValue}>
      {children}
      {isOpen ? (
        <ModalUpgradeUser onClosed={contextValue.closeUpgradeModal} />
      ) : null}
    </UpgradeModalContext.Provider>
  );
}

export function useUpgradeModal() {
  return useContext(UpgradeModalContext);
}
