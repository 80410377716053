import _kebabCase from "lodash/kebabCase";
import _isNumber from "lodash/isNumber";

/**
 * Converts typography style props object to a css string
 */
export function typographyStyleToCss(typographyObj: {
  [key: string]: string | number;
}): string {
  let css = "";

  Object.entries(typographyObj).forEach(([key, value]) => {
    const transformedKey = _kebabCase(key);
    const transformedValue =
      key === "fontSize" && _isNumber(value) ? `${value}px` : value;

    css += `${transformedKey}: ${transformedValue};\n`;
  });

  return css;
}
