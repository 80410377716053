import {
  ColorsCSS as Colors,
  Modal,
  ModalContentBody,
  ModalContentHeader,
  Spacer,
  Text,
} from "@zapier/design-system";
import { useUser } from "lib/context/user-context";
import PricingCard, { PricingButtonText } from "./Pricing/PricingCard";
import {
  getInterfacesBillingRedirectUrl,
  getInterfacesBillingUrlForPlan,
} from "lib/route-helpers";
import { styled } from "lib/theme";
import { CustomModalContent as ModalContent } from "./Modal/CustomModalContent";
import Link from "next/link";
import { useAccountPlan } from "lib/hooks/useAccountPlan";
import { AccountPlan } from "../server/schemas/user";

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PricingCardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const LearnMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LearnMore = styled(Link)`
  color: ${Colors.blue600};
  text-decoration: underline;
`;

const CustomModalContent = styled(ModalContent)`
  overflow: hidden;
`;

export function ModalUpgradeUser(props: { onClosed: () => void }) {
  const { user } = useUser();
  const { isAdvanced, isPremium, isFree } = useAccountPlan(user);

  if (!user) throw new Error("User should be defined");

  const getAdvancedButtonText = (): PricingButtonText => {
    if (isPremium()) return "Upgrade";

    return "Upgrade";
  };

  const getPremiumButtonText = () => {
    if (isAdvanced()) return "Downgrade";
    return "Upgrade";
  };

  const getPlanHref = (planName?: AccountPlan) => {
    const redirectUrl = getInterfacesBillingRedirectUrl();
    const billingUrl = getInterfacesBillingUrlForPlan(planName, redirectUrl);
    return billingUrl;
  };

  return (
    <Modal onClosed={props.onClosed}>
      <CustomModalContent width="970px">
        <ModalContentHeader align="center">
          <TitleWrapper>
            <Text type="pageHeader4" textAlign="center">
              Interfaces plans and pricing
            </Text>
            <Text type="paragraph1" textAlign="center">
              Build powerful apps with Zapier Interfaces for your team,
              customers, or yourself. Connect your data—wherever it lives—and
              automate everything. No coding required.
            </Text>
          </TitleWrapper>
        </ModalContentHeader>
        <Spacer height={20} />
        <ModalContentBody scrollable>
          <PricingCardsWrapper>
            <PricingCard
              title="Free"
              description="For individuals who are just trying out Interfaces."
              price="$0 USD"
              isCurrentPlan={!!isFree()}
              includes={["Component library", "2 Interfaces projects"]}
              priceSubText="Nada. Zippo. Zilch."
              buttonText={"Downgrade"}
              href={getPlanHref("free")}
            />
            <PricingCard
              title="Pro"
              description="For individuals who want to build professional-looking apps."
              price="$20 USD"
              isCurrentPlan={!!isPremium()}
              featuresTitle="Everything in Free, plus:"
              includes={["Custom branding", "5 Interfaces projects"]}
              priceSubText="Per month"
              buttonText={getPremiumButtonText()}
              href={getPlanHref("premium")}
            />
            <PricingCard
              title="Advanced"
              description="For teams that want to build for themselves and customers."
              price="$100 USD"
              isCurrentPlan={!!isAdvanced() || user.isStaff}
              featuresTitle="Everything in Pro, plus:"
              priceSubText="Per month"
              includes={["Custom domain", "20 Interfaces projects"]}
              href={getPlanHref("advanced")}
              buttonText={getAdvancedButtonText()}
            />
          </PricingCardsWrapper>
          <Spacer height={20} />
          <LearnMoreWrapper>
            <LearnMore href="/pricing">Learn more and compare plans</LearnMore>
          </LearnMoreWrapper>
        </ModalContentBody>
      </CustomModalContent>
    </Modal>
  );
}
