import React from "react";
import { Button, Icon, Shadows, Text } from "@zapier/design-system";
import styled from "styled-components";
import { capitalize } from "lodash";

const Wrapper = styled.li`
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: ${Shadows.elevation5};
  display: grid;
  align-items: center;
  text-align: left;
  gap: 10px;
  grid-template-columns: 30px 1fr 160px;
`;

const TextWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
`;

const getIcon = (kind: Kind) => {
  switch (kind) {
    case "chatbot":
      return "navAIChatbot";
    case "zap":
      return "miscBoltAltFill";
    case "interface":
      return "navInterfaces";
    case "table":
      return "navTables";
    case "canvas":
      return "navCanvas";
    default:
      return "miscBoltAltFill";
  }
};

type Kind = "chatbot" | "zap" | "interface" | "table" | "canvas";

interface ResourceCardProps {
  kind: Kind;
  href: string;
  name: string;
  trackClick: (kind: Kind) => void;
}

const ResourceCard = ({ kind, href, name, trackClick }: ResourceCardProps) => {
  return (
    <Wrapper>
      <Icon name={getIcon(kind)} color="brand" />
      <TextWrapper>
        <Text type="subHeader5Bold">{name}</Text>
      </TextWrapper>
      <Button
        href={href}
        onClick={() => trackClick(kind)}
        size="xsmall"
        color="tertiary"
      >
        View {capitalize(kind)}
      </Button>
    </Wrapper>
  );
};

export default ResourceCard;
