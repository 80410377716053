import { Icon, TextInput } from "@zapier/design-system";
import { ChangeEvent, useEffect, useState } from "react";

interface SearchInputProps {
  handleChange: (value: string) => void;
  placeholder: string;
  value: string;
}

export default function SearchInput({
  handleChange,
  placeholder,
  value: initialValue,
}: SearchInputProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleChange(value);
    }, 300); // Introducing a delay of 300ms

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [handleChange, value]);

  return (
    <TextInput
      placeholder={placeholder}
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      renderIconBefore={() => <Icon name="navSearch" />}
    />
  );
}
