import { Badge, Menu, MenuItem, Spacer, Text } from "@zapier/design-system";
import { styled } from "lib/theme";
import SearchInput from "components/SearchInput";
import { ProjectCountOutput, ProjectList } from "utils/trpc";

const SidebarWrapper = styled.div`
  flex: 1 1 0%;
  min-width: 220px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const ProjectCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function Sidebar({
  projectsCount,
  query,
  setQuery,
  sharing,
  setSharing,
}: {
  projects?: ProjectList;
  projectsCount?: ProjectCountOutput;
  query: string;
  setQuery: (query: string) => void;
  sharing: "my" | "shared" | "all";
  setSharing: (sharing: "my" | "shared" | "all") => void;
}) {
  return (
    <SidebarWrapper>
      <Header>
        <InterfacesLogo />
        <Spacer height={20} />
        <SearchInput
          placeholder="Search for an Interface"
          handleChange={setQuery}
          value={query}
        />
        <Spacer height={20} />
        <Menu ariaLabel="List of items">
          {projectsCount ? (
            <>
              <MenuItem
                onClick={() => setSharing("my")}
                isSelected={sharing === "my"}
              >
                <ProjectCountWrapper>
                  <span>My Interfaces</span>
                  <Badge
                    color={sharing === "my" ? "info-bold" : "white"}
                  >{`${projectsCount?.all - projectsCount?.shared}`}</Badge>
                </ProjectCountWrapper>
              </MenuItem>
              <MenuItem
                onClick={() => setSharing("shared")}
                isSelected={sharing === "shared"}
              >
                <ProjectCountWrapper>
                  <span>Shared with me</span>
                  <Badge
                    color={sharing === "shared" ? "info-bold" : "white"}
                  >{`${projectsCount?.shared}`}</Badge>
                </ProjectCountWrapper>
              </MenuItem>
              <MenuItem
                onClick={() => setSharing("all")}
                isSelected={sharing === "all"}
              >
                <ProjectCountWrapper>
                  <span>All Interfaces</span>
                  <Badge
                    color={sharing === "all" ? "info-bold" : "white"}
                  >{`${projectsCount?.all}`}</Badge>
                </ProjectCountWrapper>
              </MenuItem>
            </>
          ) : null}
        </Menu>
      </Header>
    </SidebarWrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const InterfacesLogo = () => {
  return (
    <Wrapper>
      <Text type="pageHeader5" tag={"h2"}>
        Interfaces
      </Text>
    </Wrapper>
  );
};
